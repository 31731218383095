.Contact {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1% 25% auto 1%;
  grid-template-rows: 10% auto 15% 1%;
  grid-template-areas:
    ". . . ."
    ". main main ."
    ". footer . ."
    ". . . .";
    grid-row-gap: .25rem;
    grid-column-gap: .25rem;
  

    .main {
      grid-area: main;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: 10% auto auto 10%;
      grid-template-rows: 1% auto 1%;
      grid-template-areas:
        ". . . ."
        ". left form ."
        ". . . .";

        .leftContact {
          grid-area: left;

          .sendIt {
            color: var(--color-main);
            text-decoration: none;
          }
        }

        .form {
          margin-top: 8vh;
          grid-area: form;
          word-wrap: break-word;
              white-space: normal;
          
          div {
            word-wrap: break-word;
              white-space: normal;
            h3 {
              font-size: calc(.5rem + .5vw);
            }

            input {
              display: flex;
              flex-wrap: wrap;
              background: transparent;
              border: none;
              border-bottom: solid 1px grey;
              width: 100%;
              height: 100%;
              color: var(--color-main);
              font-size: calc(.3rem + .5vw); 
              margin-bottom: 50px;  
              -webkit-transition: all 0.50s ease-in-out;
              -moz-transition: all 0.50s ease-in-out;
              -ms-transition: all 0.50s ease-in-out;
              -o-transition: all 0.50s ease-in-out; 
              
            }

            input:focus {
              box-shadow: 0 0 5px var(--color-main);
              border: 1px solid var(--color-main);
              outline-color:var(--color-main);
            }
            input:-webkit-autofill {
              -webkit-text-fill-color: var(--color-main) !important;
              -webkit-box-shadow: 0 0 0 30px black inset !important;
            };
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active  {
              -webkit-box-shadow: 0 0 0 30px black inset !important;
            }
          }
          
          .message {
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: solid 1px grey;
            font-size: calc(.3rem + .5vw); 
            resize: vertical;
            overflow: auto;
            color: var(--color-main);
            -webkit-transition: all 0.50s ease-in-out;
            -moz-transition: all 0.50s ease-in-out;
            -ms-transition: all 0.50s ease-in-out;
            -o-transition: all 0.50s ease-in-out;
          }

          .message:focus {
            box-shadow: 0 0 5px var(--color-main);
              border: 1px solid var(--color-main);
              outline-color:var(--color-main);
          }
          

          button {
            margin-top: 25px;
            background: transparent;
            color: var(--color-main);
            -webkit-transition: all 0.50s ease-in-out;
            -moz-transition: all 0.50s ease-in-out;
            -ms-transition: all 0.50s ease-in-out;
            -o-transition: all 0.50s ease-in-out;

            h3 {
              font-size: calc(.5rem + .5vw);
              align-self: center;
              text-transform: capitalize;
              padding: 0;
              margin:10px;

            }
          }

          button:hover {
            box-shadow: 0 0 10px var(--color-main);
              border: 1px solid var(--color-main);
          }
         
          button:focus {
            box-shadow: 0 0 15px var(--color-main);
              border: 1px solid var(--color-main);
              outline-color:var(--color-main);
          }
        }

        h1 {
          font-size: calc(3rem + 1vw);
        }

    }

    .footer {
      grid-area: footer;
    }
}

@media only screen and (max-width: 768px) {
  .Contact {
    display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 15% 1%;
  grid-template-areas:
    "main"
    "footer"
    ".";

    .main {
      grid-template-columns: 100%;
      grid-template-rows: 20% 80%;
      grid-template-areas:
        "left"
        "form";

        .leftContact {
          justify-self: center;
          text-align: center;

          h1 {
            margin: 0;
          }

          .sendIt {
            margin-top: 0;
            padding-top: 0;
          }
        }

        .form {
          width: 80%;
          justify-self:center;

        }
    }

    .footer {
      display: flex;
      justify-content: center;
    }
  }
}