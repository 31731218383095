.HomeMain {
  height: 100%;
  padding-left: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;

  .title {
    margin: 0;
    font-size: calc(1.2rem + 1vw);
  }

  .name {
    display: flex;

    h1 {
      color: var(--color-main);
      margin: 0;
      font-size: 8vw;
      letter-spacing: .3rem;
    }

    p {
      margin: 0;
    }
  }

  .paragraph {
    width: 70%;
    font-size: calc(.1rem + 1vw);
  }

  .link {
    max-width: 450px;
    align-items: center;
    text-decoration: none;
    color: white;
  }
}  

.bg1 {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../Assets/me-donut.jpg') no-repeat center center;
  background-size: cover; 
}
.bg2 {
  position: absolute;
  width: 60%;
  height: 60%;
  top: 0;
  right: 3%;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../Assets/instagram.png') no-repeat center center;
  background-size: contain; 
}
.bg0 {
  position: absolute;
  width: 60%;
  height: 60%;
  top: 3%;
  right: 5%;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../Assets/lock.png') no-repeat center center;
  background-size: contain; 
}
.bg3 {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../Assets/me-airplane.jpg') no-repeat center center;
  background-size: cover; 
}


