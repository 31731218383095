.Footer {
 height: 100%;
 max-width: 300px;
 display: flex;
 flex-direction: column;

  .followMe {
    text-align: center;
    font-size: 1.3rem;
  }

  .icons {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon {
      color: white;
      width: calc(15px + 1vw);
    height: calc(15px + 1vw);
    transition: transform .4s ease;
    }
    .icon:hover {
      transform: scale(1.3);
    }
  }
}

@media only screen and (max-width: 768px) {
  .Footer {
    width: 100%;
   }
}
