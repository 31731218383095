.burger {
  position: absolute;
  top: 20%;
  right: calc(1rem + 2vw);
  z-index: 5;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 2rem;
height: 2rem;
background: transparent;
border: none;
cursor: pointer;
padding: 0;

div {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

}

.burger:focus {
  outline: none;
}
