.MenuMobile {
display: flex;
flex-direction: column;
background: white;
opacity: 0.8;
height: 100vh;
text-align: left;
padding-left: calc(1rem + 1vw);
padding-right: calc(1rem + 1vw);
position: absolute;
top: 0;
right: 0;
transition: 0.5s ease-in-out;
-webkit-box-shadow: 3px 3px 5px 6px white;  
-moz-box-shadow:    3px 3px 5px 6px white;  
box-shadow:         3px 3px 5px 6px white; 

.link {
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: calc(1rem + 1vw);
  text-transform: uppercase;
  padding: calc(1rem + 1vw);
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: grey;
  text-decoration: none;
  transition: .5s;
  border-bottom:solid 1px black;

  p {
    font-size: calc(.3rem + 1vw);
    margin-left: calc(.3rem + 1vw);
  }
}

.link:hover {
  color: black;
  border-bottom: solid 1px black;
  transform: scale(1.1);

}
}


