.About {
  position: relative;

  h1 {
    font-size: calc(3rem + 1vw);
  }

  p {
    font-size: calc(.4rem + .5vw);
    font-weight: 400;
  }

  h3 {
    font-size: calc(.8rem + .5vw);
    font-weight: 400;
  }

  .aboutMe {
    width: 100%;
    height:100vh;
    top: 0;
    left: 0;
    position: fixed !important;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Assets/nacho-and-me.png') no-repeat center center;
    background-size: cover; 
    background-attachment: fixed !important;
    z-index:-2;
  }
  
      .aboutH1 {
        position: sticky;
        position: -webkit-sticky;
        width: 100%;
        height: 100vh;
        font-size: calc(6rem + 1vw);
        text-align: center;
        top:25%;
        z-index: -1;
      }

  .bottom {
    background-color: black;
    width: 100%;
    height: 100%;
    margin-top:-27vh;
    display: grid;
    grid-template-columns: 12% auto 12%;
    grid-template-rows: auto auto auto auto 7%;
    grid-template-areas:
      ". story ."
      ". skills ."
      ". experience ."
      ". footer ."
      ". . .";
      grid-row-gap: 8rem;
    
    .myStory {
      grid-area: story;
      p {
        column-count: 2;
      }
    }

    .mySkills {
      grid-area: skills;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .leftSkill {
        width: 50%;
        line-height: 0;
        margin-right: 1rem;
      }

      .rightSkill {
        width: 50%;
        div {
          display: flex;
          align-items: center;

          p {
            margin-right: 1rem;
          }
          .icon {
            margin-right: 1rem;
          }
        }
      }
    }

    .experience {
      grid-area: experience;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .leftEx {
        width: 50%;
        line-height: 0;
        margin-right: 1rem;
      }

      .rightEx {
        width: 50%;

        p {

        }
      }

    }

    .footer {
      grid-area: footer;
      width: 100%;
    }

    .links {
      grid-area: links;
      width: 100%;
      height: 100%;
      display: flex;

      .linkLeft {
        width: 50%;
        height: 100%;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        background: black;
        padding-left: 2rem;
        padding-top: 2rem;
        text-align: center;
        h1 {
          padding: 0;
          margin:0;
        }
        p {
          padding: 0;
          margin:0;
        }
        
      }

      .linkRight {
        width: 50%;
        height: 100%;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        background: black;
        padding-left: 2rem;
        padding-top: 2rem;
        text-align: center;
        h1 {
          padding: 0;
          margin:0;
        }
        p {
          padding: 0;
          margin:0;
        }
      }
    }
  } 
}
@media only screen and (max-width: 768px) {
  .About {

  h1 {
    font-size: calc(1.1rem + 1vw);
  }

  h3 {
    font-size: calc(.6rem + .5vw);
  }

  p{
    font-size: calc(.5rem + .5vw);
  }
  .aboutH1 {
    top:30%;
  }

  .bottom {
    grid-row-gap: 4rem;

    .links {
      display: flex;
      flex-direction: column;

      .linkLeft {
        width: 80%;
        margin-bottom:.5rem;
        min-height: 200px;
        align-items: center;
      }
      
      .linkRight {
        width: 80%;
        min-height: 200px;
        align-items: center;
      }
    }

    .footer {
      display: flex;
      justify-content: center;
    }
  }
}
}