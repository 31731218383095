.ScrollBarMobile {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns:  40% 40%;
  grid-template-areas:
    "leftArrow rightArrow";
    justify-content: center;
    align-items:center;

  .leftArrow {
    grid-area: leftArrow;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .rightArrow {
    grid-area: rightArrow;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}