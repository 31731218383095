.Vault {
  grid-template-columns: 1% 25% auto 1%;
  grid-template-rows: 10% auto 15% 1%;
  grid-template-areas:
    ". . . ."
    ". main main ."
    ". footer . ."
    ". . . .";
    grid-row-gap: .25rem;
    grid-column-gap: .25rem;
    display: flex;
    flex-direction: column;


    .main {
      justify-content: center;
      align-items: center;
      grid-area: main;
      height: 100%;
      width: 100%;
        .title {
            font-size: 10rem;
            text-align: center;
        }
    
        .words {
            font-size: 5rem;
            text-align: center;
        }
    }

    .footer {
        grid-area: footer;
      }

}

@media only screen and (max-width: 768px) {
    .Vault {
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto 15% 1%;
        grid-template-areas:
          "main"
          "footer"
          ".";
        .main {
            .title {
                font-size: 5rem;
            }
        
            .words {
                font-size: 3rem;
            }
        }
        .footer {
            display: flex;
            justify-content: center;
          }
    }
  }