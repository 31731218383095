.Nav {
  position: relative;
  z-index: 7;
  display: grid;
  grid-template-columns: 2% 8% auto 8% 1%;
  grid-template-rows: 1% auto 1%;
  grid-template-areas: 
    ". . . . ."
    ". left . right ."
    ". . . . .";

  .left{
    grid-area: left;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      text-align: center;
      text-decoration: none;
      color: white;

      img {
        width: 90%;
        max-width: 100px;
        height: auto;
        padding-bottom: .5rem;
        transition: transform .4s ease;
      }
      img:hover {
        transform: scale(1.3) translateX(10px) translateY(10px);
      }
    }
  }

  .right {
    grid-area: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}